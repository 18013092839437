import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import XLSX from 'xlsx';

export default function ProductXlsxDownloadModal(props) {
  const {visible, setVisible} = props;

  return (
    <Ant.Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
      closable={true}
      maskClosable={true}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [downloadLink, setDownloadLink] = React.useState(null);

  const productRowLabels = [
    '商品型號',
    '名稱',
    '價錢',
    '商品等級',
    '性別',
    '分類標籤',
    '尺寸',
    '寬度',
    '版型',
    '行銷款',
    'assortment',
    '正掛',
    '產品說明',
    '備註',
  ];

  function productToRow(product) {
    return [
      product.model,
      product.name,
      product.price,
      (product.levels || [])
        .reduce((acc, l) => {
          return acc + l + ',';
        }, '')
        .slice(0, -1),
      product.gender,
      (product.labels || []).reduce((acc, label) => `${acc}\n ${label}`, ''),
      product.size,
      product.width,
      product.pattern,
      product.marketing,
      product.assortment,
      product['正掛'],
      product.description,
      product.note,
    ];
  }

  async function createDownloadLink() {
    AppActions.setLoading(true);
    try {
      const products = await JStorage.fetchAllDocuments('product', {}, [
        'model',
      ]);

      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.aoa_to_sheet([
        productRowLabels,
        ...products.filter((product) => product.type === 1).map(productToRow),
      ]);

      const ws2 = XLSX.utils.aoa_to_sheet([
        productRowLabels,
        ...products.filter((product) => product.type === 2).map(productToRow),
      ]);

      const ws3 = XLSX.utils.aoa_to_sheet([
        productRowLabels,
        ...products.filter((product) => product.type === 3).map(productToRow),
      ]);

      XLSX.utils.book_append_sheet(wb, ws1, '鞋');
      XLSX.utils.book_append_sheet(wb, ws2, '衣');
      XLSX.utils.book_append_sheet(wb, ws3, '配');

      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array',
        cellStyles: true,
        bookImages: true,
      });

      const objUrl = URL.createObjectURL(
        new Blob([wbout], {type: 'application/octet-stream'}),
      );
      setDownloadLink(objUrl);
      Ant.message.success('成功創建下載連結');
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <h2>下載產品列表</h2>
      <div className="hint">
        注意: 此下載功能l與上傳excel的格式不同, 僅供用於檢查商品資料,
        請勿將此檔案直接用於上傳功能!
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Ant.Button onClick={createDownloadLink}>產生下載連結</Ant.Button>
        {downloadLink && (
          <Ant.Button
            href={downloadLink}
            target="_blank"
            download={'product-list.xlsx'}
            type="link">
            點此下載
          </Ant.Button>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 20px;

  & > .hint {
    padding: 20px;
    border-radius: 16px;
    background-color: #eee;
    margin: 20px 0;
  }
`;
