import React from 'react';
import styled from 'styled-components';
import {Button, Tag} from 'antd';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import ArticleEditor from 'rev.sdk.js/Components/ArticleEditor';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getOutlet} from 'reconnect.js';
import UploadProductXlsx from './UploadXlsx';
import NbSingleImageUploader from './NbSingleImageUploader';
import * as AppActions from '../../AppActions';
import ProductXlsxDownloadModal from './ProductXlsxDownloadModal';
import NbZipUploadButton from '../../Components/NbZipUpload';
import AdminArticleBelowSection from './AdminArticleBelowSection';

function AdminResourcePage(props) {
  const {path, pageContext} = props;
  const [
    showDownloadProductModal,
    setShowDownloadProductModal,
  ] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      const dealers = await JStorage.fetchAllDocuments('user_profile');
      getOutlet('dealers').update(dealers);
    }

    fetchData();
  }, []);

  function renderCustomCol(props) {
    const {col, record} = props;
    if (col.customType === 'label') {
      return record.label?.map((l, idx) => <Tag>{l}</Tag>);
    } else if (col.customType === 'site-config-name') {
      return (
        <Button
          type="link"
          target="_blank"
          href={`/admin/${record.name}/?action=detail&id=${record.id}`}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(
              `/admin/${record.name}/?action=detail&id=${record.id}`,
            );
          }}>
          {record.name || '---'}
        </Button>
      );
    } else if (col.customType === 'product_model') {
      return (
        <Button
          type="link"
          target="_blank"
          href={`/admin/products?action=detail&id=${record.id}`}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(
              `/admin/products?action=detail&id=${record.id}`,
            );
          }}>
          {record.model || '---'}
        </Button>
      );
    } else if (col.customType === 'product_images_count') {
      return record?.images?.length;
    } else if (col.customType === 'user_levels') {
      return (record?.levels || []).sort().join(' / ');
    } else if (col.customType === 'product_levels_display') {
      if (Array.isArray(record?.levels)) {
        return record?.levels?.map((l, idx) =>
          idx !== record?.levels.length - 1 ? l + ', ' : l,
        );
      } else {
        return record?.levels;
      }
    } else if (col.customType === 'product_type_display') {
      let display = '';
      if (record?.type === 1) {
        display = '鞋';
      } else if (record?.type === 2) {
        display = '衣';
      } else if (record?.type === 3) {
        display = '配';
      }
      return display;
    } else if (col.customType === 'nb-video-access') {
      const accessLog = record.access || {};
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 5,
          }}>
          {Object.keys(accessLog).map((name) => {
            return (
              <div
                style={{
                  padding: '3px 5px',
                  border: '1px solid #aaa',
                  borderRadius: 3,
                  fontSize: 10,
                }}>
                {name}/{accessLog[name]}
              </div>
            );
          })}
        </div>
      );
    } else if (col.customType === 'nb-batch-image-req_id') {
      return <div>{record.req_id}</div>;
    } else if (col.customType === 'nb-batch-image-pending_upload_count') {
      const estDtMin = new Date(
        record.created +
          (5 + 0.2 * (record.pending_processing || []).length) * 60 * 1000,
      );

      const estDtMax = new Date(
        record.created +
          (15 + 1 * (record.pending_processing || []).length) * 60 * 1000,
      );
      return (
        <>
          <div>
            * 預估完成時間: {estDtMin.toLocaleString('sv')} ~{' '}
            {estDtMax.toLocaleString('sv')}
          </div>
          <div style={{color: '#888'}}>
            {(record.pending_processing || []).map((it) => {
              return (
                <div>
                  {it.model} {(it.images || []).length}
                </div>
              );
            })}
          </div>
        </>
      );
    }
    return null;
  }

  function renderCustomSection(props) {
    const {name, type, context} = props;
    console.log('DBGGGGG', name, type, context);

    if (path === '/admin/products' && context.position === 'middle') {
      return <UploadProductXlsx />;
    } else if (
      type === 'form' &&
      name === 'UserAddonFeat' &&
      context.position === 'top'
    ) {
      return (
        <p style={{fontWeight: 'bold', fontSize: 22, marginTop: 30}}>
          帳號/經銷商編號:{' '}
          <span style={{backgroundColor: 'lightblue'}}>
            {context.instance.owner}
          </span>
        </p>
      );
      // } else if (
      //   type === 'form' &&
      //   name === 'ArticleFormHi' &&
      //   context.position === 'top'
      // ) {
      //   console.log('DBGGG', context?.instance);
      //   return (
      //     <ArticleEditor
      //       collection={{name: 'Article_Default'}} // will be removed in sdk
      //       onUpdate={() => 0} // will be removed in sdk
      //       onClose={() => 0} // will be removed in sdk
      //       document={context?.instance}
      //     />
      //   );
    } else if (type === 'form' && name === 'ArticleForm') {
      console.log('DBGGG', context);
      if (context.position === 'top') {
        return <h2 style={{margin: '15px 0'}}>基本資料</h2>;
      }

      if (context.position === 'below-form') {
        return (
          <AdminArticleBelowSection
            context={context}
            name={name}
            type={type}
            path={path}
            multilingual={path === '/admin/i18n-articles'}
          />
        );
      }
    }
    return null;
  }

  if (path.indexOf('/admin/products') > -1) {
    pageContext.resource.renderCreateButton = () => (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Button
          onClick={() => AppActions.navigate('/admin/products/?action=create')}>
          +
        </Button>

        <Button
          style={{marginLeft: 10}}
          type="primary"
          onClick={() => setShowDownloadProductModal(true)}>
          下載 excel 檔案
        </Button>

        <Button
          style={{marginLeft: 10}}
          type="link"
          href={'/nb-e-catalog-template.xlsx'}
          download={'nb-e-catalog-template.xlsx'}>
          下載 excel 模板
        </Button>

        <ProductXlsxDownloadModal
          visible={showDownloadProductModal}
          setVisible={setShowDownloadProductModal}
        />
      </div>
    );
  }

  if (path.indexOf('/admin/batch-image-upload') > -1) {
    pageContext.resource.renderCreateButton = () => (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <NbZipUploadButton />
      </div>
    );
  }

  const rjsfProps = {
    widgets: {
      'nb-image-widget': NbSingleImageUploader,
    },
  };

  return (
    <Wrapper>
      <AdminResource
        {...props}
        renderCustomAdminCol={renderCustomCol}
        renderCustomAdminSection={renderCustomSection}
        rjsfProps={rjsfProps}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  #nb-product-category-form {
    max-width: 1440px;
  }
`;

export default AdminResourcePage;
