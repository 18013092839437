import React from 'react';
import {Button} from 'antd';
import * as AppActions from '../../AppActions';

export default function NbSingleImageUploader(props) {
  const [fetching, setFetching] = React.useState(false);
  const [dealerImg, setDealerImg] = React.useState(null);
  const fetchCntRef = React.useRef(0);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const fileInputRef = React.useRef(null);

  React.useEffect(() => {
    async function fetchDealerImg() {
      setFetching(true);
      const fetchCnt = ++fetchCntRef.current;
      try {
        const [_dealerImg] = await AppActions.getImagesWithDealerV2([
          props.value,
        ]);
        // use the latest fetch result as our final image
        if (fetchCntRef.current === fetchCnt) {
          setDealerImg(_dealerImg);
        }
      } catch (ex) {
        console.warn('NbSingleImageUploader.useEffect ex', ex);
      }
      setFetching(false);
    }

    if (props.value) {
      const [, _dealerImg] = props.value.split('___');
      if (_dealerImg) {
        // still need to advance the fetchCnt to disable the existing fetch
        ++fetchCntRef.current;
        setDealerImg(_dealerImg);
      } else {
        fetchDealerImg();
      }
    }
  }, [props.value]);

  if (props.value) {
    return (
      <div
        style={{
          border: '2px solid #ccc',
          borderRadius: 6,
          padding: '0 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {fetching && <div>載入中...</div>}
        {dealerImg && (
          <img
            src={dealerImg}
            style={{
              width: 180,
              height: 180,
              objectFit: 'contain',
              backgroundColor: '#eee',
            }}
            alt="product"
          />
        )}
        <h3>{props.value}</h3>
      </div>
    );
  } else {
    return (
      <div
        style={{
          border: '2px solid #ccc',
          borderRadius: 6,
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {selectedFile && (
          <img
            src={selectedFile.blob}
            style={{
              width: 180,
              height: 180,
              objectFit: 'contain',
              backgroundColor: '#eee',
            }}
            alt="product"
          />
        )}
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={() => {
            const file = fileInputRef.current.files
              ? fileInputRef.current.files[0]
              : null;
            if (file) {
              const objUrl = URL.createObjectURL(file);
              setSelectedFile({
                name: file.name,
                blob: objUrl,
              });
            } else {
              setSelectedFile(null);
            }
          }}
        />
        {selectedFile && (
          <Button
            onClick={() => {
              props.onChange(`${selectedFile.name}___${selectedFile.blob}`);
            }}>
            確認
          </Button>
        )}
      </div>
    );
  }
}
