import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import DataJson from '../../../data.json';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getOutlet} from 'reconnect.js';

/**
 get the final images through AWS CLI:
 `mkdir -p pending_uploads && cd pending_uploads && aws s3 cp s3://newbalance-revtel2-com-prod/__image_upload__/<req_id> . --recursive`
 */

export default function NbZipUploadButton(props) {
  const {style = {}} = props;
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Ant.Button style={style} onClick={() => setShowModal(true)}>
        批次上傳產品圖片
      </Ant.Button>
      <NbZipUploadModal visible={showModal} setVisible={setShowModal} />
    </>
  );
}

function NbZipUploadModal(props) {
  const {visible, setVisible} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

async function uploadFile({file, type, key, onSuccess}) {
  try {
    const resp = await ApiUtil.req(
      `${DataJson.storageHost}/storage/presigned/url?client_id=${
        DataJson.clientId
      }&token=${getOutlet('user').getValue().token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          'Content-Type': type,
          key,
          unique_key: false,
          acl: 'private',
        },
      },
    );

    const {expected, fields, url} = resp;
    const formData = new FormData();

    for (const key in fields) {
      formData.set(key, fields[key]);
    }
    formData.set('file', file);

    const uploadResp = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (uploadResp.status.toString()[0] !== '2') {
      throw uploadResp;
    }

    await onSuccess(expected);
  } catch (ex) {
    console.warn(ex);
  }
}

function handleZipEntry(path) {
  if (path.indexOf('__MACOSX') !== 0) {
    const parts = path.split('/');
    const iname = parts[parts.length - 1];
    const pname = parts[parts.length - 2];
    if (pname && iname) {
      const [ext] = iname.split('.').slice(-1);
      const allowedExt = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'];
      if (allowedExt.indexOf(ext) > -1) {
        return [pname, iname];
      }
    }
  }
  return false;
}

function ModalContent(props) {
  const [allProducts, setAllProducts] = React.useState([]);
  const [productImages, setProductImages] = React.useState({});
  const [zipFile, setZipFile] = React.useState(null);
  const [successUploads, setSuccessUploads] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      setAllProducts(
        await JStorage.fetchAllDocuments('product', {}, null, {
          model: 1,
          name: 1,
          price: 1,
        }),
      );
    }

    fetchData();
  }, []);

  async function onParseZip() {
    AppActions.setLoading(true);
    await AppActions.delay(600);
    const elem = document.getElementById('rev-zip-file-input');
    if (elem) {
      const file = elem.files[0];
      if (file) {
        try {
          const new_zip = new window.JSZip();
          const _productImages = {};
          const zip = await new_zip.loadAsync(file);
          zip.forEach(function (relativePath, zipEntry) {
            if (!zipEntry.dir) {
              const result = handleZipEntry(relativePath);
              if (result) {
                const [pname, iname] = result;
                if (!_productImages[pname]) {
                  _productImages[pname] = {
                    model: pname,
                    images: [],
                  };
                }
                _productImages[pname].images.push({
                  name: iname,
                  zipEntry,
                });
              }
            }
          });

          setProductImages(_productImages);
        } catch (ex) {
          console.warn(ex);
          Ant.message.error('ZIP檔案格式有誤');
        }
      }
    }
    AppActions.setLoading(false);
  }

  async function onUploadImages() {
    AppActions.setLoading(true);
    const now = new Date();
    const req_id = `${now
      .toLocaleString('sv')
      .replace(' ', '_')}_${now.getTime()}`;

    let idx = 0;
    const allPendingProductModels = Object.keys(productImages).filter((model) =>
      allProducts.find((it) => it.model === model),
    );
    const _successUploads = [];
    for (const model of allPendingProductModels) {
      Ant.message.success(
        `正在處理第${++idx}/${
          Object.keys(allPendingProductModels).length
        }個商品...`,
      );

      const product = productImages[model];
      const pendingUploads = [];
      try {
        for (const it of product.images) {
          const arrBuf = await it.zipEntry.async('arraybuffer');
          const blob = new Blob([arrBuf], {type: 'image/png'});
          await uploadFile({
            file: blob,
            type: blob.type,
            key: `__image_upload__/${req_id}/${model}/${it.name}`,
            onSuccess: (expectedUrl) => {
              console.log('uploaded to ', expectedUrl);
              pendingUploads.push(expectedUrl);
            },
          });
        }

        _successUploads.push({model, images: pendingUploads});
      } catch (ex) {
        console.warn(ex);
        Ant.message.info(`商品${model}未能成功處理`);
      }
    }

    try {
      await JStorage.createDocument('image_upload', {
        req_id,
        pending_processing: _successUploads,
        status: 'waiting',
      });
      setSuccessUploads(_successUploads);
      Ant.message.success(`上傳完畢...`);
      Ant.message.success(`雲端伺服器排程處理中...`);

      await AppActions.delay(1000);

      const ghResp = await ApiUtil.req(
        `${DataJson.nbImgHost}/imgproc?token=${
          getOutlet('user').getValue().token
        }&req_id=${req_id}`,
      );

      if ((ghResp?.gh_status || 999).toString()[0] === '2') {
        Ant.message.success(`成功加入雲端處理，請稍待頁面重整...`);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (ex) {
      Ant.message.error('API 錯誤: ' + ex);
    }

    AppActions.setLoading(false);
  }

  if (Array.isArray(successUploads)) {
    return (
      <Wrapper>
        <h1>批次上傳結果</h1>
        {successUploads.map((it, idx) => {
          return (
            <div key={idx} style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flex: 1, color: 'green'}}>
                產品 {it.model} 圖片共{' '}
                {Array.isArray(it.images) ? it.images.length : 0} 張已成功上傳
              </div>
            </div>
          );
        })}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1>批次上傳產品圖片</h1>

      <div className="upload-input-form">
        <input
          type="file"
          id="rev-zip-file-input"
          onChange={(e) => setZipFile(e.target.files[0])}
        />
      </div>

      {Object.keys(productImages).length > 0 && (
        <div className="product-images-stat">
          {Object.keys(productImages)
            .sort()
            .map((it, idx) => {
              it = productImages[it];
              const productInDB = allProducts.find((p) => p.model === it.model);
              return (
                <div key={idx} style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{flex: 1}}>
                    產品 {it.model} 圖片共{' '}
                    {Array.isArray(it.images) ? it.images.length : 0} 張
                  </div>
                  {!productInDB && (
                    <div style={{marginLeft: 10, color: 'red'}}>查無該品項</div>
                  )}
                </div>
              );
            })}
        </div>
      )}

      <div className="buttons">
        <Ant.Button disabled={!zipFile} onClick={onParseZip}>
          解析
        </Ant.Button>
        <Ant.Button
          disabled={Object.keys(productImages).length === 0}
          onClick={onUploadImages}>
          上傳
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  .upload-input-form {
    margin: 20px 0;
  }

  .product-images-stat {
    & > * {
      padding: 8px 0px;
      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;
